<template>
  <div>
    <v-card
      class="mx-auto"
      outlined
    >
      <ListSkeleton v-if="listSkeleton"/>
      <v-card-text class="text-h5 px-2 pt-0" v-else-if="orderList.data">
        <slot name="closeIcon"></slot>
        <v-data-table
          :dense="true"
          class="custom-table mb-0 ma-2 row-pointer"
          :headers="headers"
          :items="orderList.data"
          :items-per-page="limit"
          :page.sync="page"
          :server-items-length="orderList.meta.total"
          :options.sync="pagination"
          item-class="py-4"
          :footer-props="{
            'items-per-page-options': [10, 20, 30, 40, 50]
          }"
          :search="search"
          @dblclick:row="doubleClick"
        >
          <template
            v-slot:top
          >
            <div class="text-body-2 primary--text mb-2">
              <v-row :dense="true">
                <v-col cols="2" class="text-end d-flex">
                  <v-autocomplete
                    hide-details="auto"
                    dense
                    v-model="selectedStatus"
                    :items="statusList"
                    item-text="name"
                    item-value="id"
                    flat
                    return-object
                    single-line
                    :label="$t('order.field.status')"
                  />
                </v-col>
                <v-col cols="3">
                </v-col>
                <v-col cols="2" class="text-end d-flex">
                  <v-text-field
                    :full-width="false"
                    :dense="true"
                    hide-details="auto"
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$t('common.search')"
                    single-line
                  />
                </v-col>
                <v-col cols="2">
                  <v-autocomplete
                    hide-details="auto"
                    dense
                    readonly
                    v-model="selectedCustomer"
                    :items="[selectedCustomer]"
                    item-text="name_1"
                    item-value="id"
                    flat
                    return-object
                    single-line
                    :label="$t('order.field.customer')"
                  />
                </v-col>
                <v-col cols="3" class="text-end d-flex">
                  <v-menu
                    ref="startDateMenu"
                    v-model="startDateMenu"
                    :close-on-content-click="false"
                    :return-value.sync="order_date"
                    transition="scale-transition"
                    offset-y
                    :nudge-left="40"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        hide-details="auto"
                        dense
                        class="me-2"
                        single-line
                        v-model="order_date"
                        :label="$t('order.from')"
                        readonly
                        clearable
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="order_date"
                      @change="$refs.startDateMenu.save(order_date)"
                    ></v-date-picker>
                  </v-menu>
                  <v-menu
                    ref="endDateMenu"
                    v-model="endDateMenu"
                    :close-on-content-click="false"
                    :return-value.sync="order_validity"
                    transition="scale-transition"
                    offset-y
                    :nudge-left="160"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        hide-details="auto"
                        dense
                        single-line
                        v-model="order_validity"
                        :label="$t('order.to')"
                        readonly
                        clearable
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :min="order_date ? order_date : ''"
                      v-model="order_validity"
                      @change="$refs.endDateMenu.save(order_validity)"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </div>
            <v-divider />
          </template>
          <template v-slot:[`item.grand_total`]="props">
            <span>{{ numberWithCommas(props.item.grand_total) }}</span>
          </template>
          <template v-slot:[`item.order_date`]="props">
            <span>{{ props.item.order_date ? new Date(props.item.order_date).toISOString().substring(0,10) : '' }}</span>
          </template>
          <template v-slot:[`item.status`]="props">
            <v-chip
              :color="getColor(props.item.status)"
              small
              dark
              @dblclick.stop="onStatusDbClick(props.item.id)"
            >
              {{ statusList.filter(x => x.id == props.item.status)[0].name }}
            </v-chip>
          </template>
          <template v-slot:[`footer.prepend`]>
            <v-row class="d-flex">
              <v-col class="mx-auto" cols="12">
                <span class="pl-3 pt-2">{{ $t('common.numberOfTotalRows') }} {{orderList.meta.total}}</span>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`footer.page-text`]>
            <v-row class="d-flex">
              <v-col class="mx-auto mr-16" cols="4">
                <v-container class="w-100">
                  <v-pagination
                    :total-visible="5"
                    v-model="page"
                    :length="orderList.meta.last_page"
                  ></v-pagination>
                </v-container>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-text class="text-caption text-center" v-else> {{$t('common.noDataFound')}}</v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ListSkeleton from '@/components/skeleton/ListSkeleton';

export default {
  name: 'OrderTable',
  components: {
    ListSkeleton,
  },
  props: ['listSkeleton', 'type', "customer_id", "selected_customer"],
  data() {
    return {
      page: 1,
      limit: 10,
      customerPage: 1,
      customerLimit: 10,
      pagination: {},
      accessRights: [],
      search: '',
      startDateMenu: false,
      endDateMenu: false,
      order_date: null,
      order_validity: null,
      selectedStatus: {
        id: 0
      },
    }
  },
  computed: {
    selectedCustomer() {
      return {
        id: this.customer_id,
        name_1: this.selected_customer
      }
    },
    statusList() {
      return [
        { name: this.$t('order.field.open'), id: 0 },
      ]
    },
    params() {
      return {
          ...this.pagination,
          query: this.search,
          order_date: this.order_date,
          order_validity: this.order_validity,
      };
    },
    ...mapGetters({
      orderList: 'order/orderList',
      accessRight: 'accessRight'
    }),
    headers() {
      return [
        {
          text: this.$t('order.id'),
          align: "start",
          sortable: true,
          value: "id",
        },
        { text: this.$t('order.field.title'), value: "title" },
        { text: this.$t('order.field.customer'), value: "customer.name_1", sortable: false },
        this.accessRight.includes('financial') ? { text: this.$t('order.field.grandTotal'), value: "grand_total" } : '',
        { text: this.$t('order.field.orderStartDate'), value: "order_date" },
        { text: this.$t('order.field.status'), value: "status" },
      ]
    },
  },
  watch: {
    type: {
      handler() {
        this.search = '';
        this.page = 1;
        this.limit = 10;
        this.startDateMenu = false;
        this.endDateMenu = false;
        this.order_date = null;
        this.order_validity = null;
        this.$emit('update:customer_id', null);
        this.$emit('update:selected_customer', null);
      },
      deep: true
    },
    params: {
      handler() {
        this.paginate();
      },
      deep: true
    }
  },
  async mounted() {
    this.$emit('update:listSkeleton', true);
    await this.$store.dispatch('order/GetOrder', {page: this.page, limit: this.limit, where_and: 'status|'+0, where: this.customer_id ? 'customer_id|'+this.customer_id : this.customer_id, order_by: 'order_date|desc'});
    this.$emit('update:listSkeleton', false);
  },
  methods: {
    numberWithCommas(x) {
      return x ? parseFloat(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : (0).toFixed(2);
    },
    onStatusDbClick(item) {
      if(item){
        this.$router.push({path: '/order/orderAction/' + item})
      }
      else {return false;}
    },
    getColor (status) {
      if (status == 0) return 'orange'
      else if (status == 1) return 'green'
      else return 'red'
    },
    async paginate() {
      const { sortBy, sortDesc, page, itemsPerPage, query, order_date, order_validity } = this.params;
      // make params for where_and
      let concateWhereAnd = '';
      let whereAndVal = '|';
      concateWhereAnd += order_date ? 'from_order_date,' : '';
      concateWhereAnd += order_validity ? 'to_order_date,' : '';
      concateWhereAnd += 'status,';
      whereAndVal += order_date ? order_date+',' : '';
      whereAndVal += order_validity ? order_validity+',' : '';
      whereAndVal += 0+',';
      // end make params for where_and
      await this.$store.dispatch('order/GetOrder', {page: page, limit: itemsPerPage, where: this.customer_id ? 'customer_id|'+this.customer_id : this.customer_id, where_and: concateWhereAnd.slice(0, -1) && whereAndVal.slice(0, -1) ? concateWhereAnd.slice(0, -1)+whereAndVal.slice(0, -1) : undefined, where_like: query ? 'id,customer_name,grand_total|'+query : 'id,customer_name,grand_total|', order_by: sortBy[0] ? sortBy[0]+'|'+(sortDesc[0] ? 'desc' : 'asc') : 'order_date|'+(sortDesc[0] ? 'asc' : 'desc')});
    },
    doubleClick(event, {item}) {
      this.$emit('selectCustomerOrder', item);
    },
  }
}
</script>
